<template>
  <v-card width="100%">
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn outlined class="mr-4" color="primary" @click="setToday">
              Hoy
            </v-btn>
            <v-btn fab text small color="primary" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar" style="width:130px;text-align: center;">
              {{ $refs.calendar.title.toUpperCase().split(" ")[0] }}
            </v-toolbar-title>
            <v-btn fab text small color="primary" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-btn fab text small color="primary" @click="move(-12)">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title.toUpperCase().split(" ")[1] }}
            </v-toolbar-title>
            <v-btn fab text small color="primary" @click="move(12)">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-menu ref="menuPrint" v-model="menuPrint" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto" :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" fab outlined x-small class="mx-5"><v-icon>mdi-calendar-clock-outline</v-icon></v-btn>
              </template>
              <v-date-picker
                :first-day-of-week="1"
                filled
                dense
                hide-details="auto"
                color="primary"
                range
                v-model="imprimirRange"
                @change="imprimir"
              ></v-date-picker>
            </v-menu>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" fab outlined x-small><v-icon>mdi-alert</v-icon></v-btn>
              </template>
              <v-date-picker
                :events="auxFecha"
                event-color="red"
                :first-day-of-week="1"
                no-title
                @input="criticas"
              ></v-date-picker>
            </v-menu>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Día</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Semana</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Mes</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 días</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              class="ml-4"
              v-if="$root.acceso('CREAR_EVENTO')"
              to="/eventos/nuevo"
              color="secondary"
            >
              <v-icon left>mdi-plus</v-icon>
              Añadir evento
            </v-btn>
          </v-toolbar>
        </v-sheet>
        <v-sheet class="pa-2">
          <v-calendar
            color="primary"
            ref="calendar"
            v-model="focus"
            :events="[...festivos,...jornadas, ...eventosFiltrados]"
            :event-color="(e) => e.color"
            @click:event="showEvent"
            @contextmenu:event="showEvent"
            :type="type"
            :key="keyHelper"
            :event-height="40"
            :event-more="false"
            :weekdays="weekdays"
          >
            <template v-slot:event="{ event }">
              <Evento :event="event" />
            </template>

            <template v-slot:day-label="{ day, date, present, past }">
              <div class="d-flex">
                <v-btn icon :color="$vuetify.theme.dark ? '#EB44B6' : '#E252F2'" v-if="hasEvents(date) && isNotPb(date)" :to="`/planificacion/${ date }`" >
                  <v-icon style="text-shadow: .6px .6px rgba(0, 0, 0, .7);" small>mdi-notebook-multiple</v-icon>
                </v-btn>
                <v-btn icon :color="$vuetify.theme.dark ? '#EB44B6' : '#E252F2'" v-if="hasEvents(date) &&  isNotPb(date) && isCat(date) && (!!($store.getters.getTokenPayload?.permisos & $store.getters.getPermisos['NECESIDADES']))" :to="`/necesidades/${ date }`" >
                  <v-icon style="text-shadow: .6px .6px rgba(0, 0, 0, .7);" small>mdi-glass-wine</v-icon>
                </v-btn>
                <v-btn icon :color="$vuetify.theme.dark ? '#EB44B6' : '#E252F2'" v-if="hasEvents(date) &&  isNotPb(date) && isCat(date)" :to="`/check_catering/${ date }`" >
                  <v-icon style="text-shadow: .6px .6px rgba(0, 0, 0, .7);" small>mdi-truck</v-icon>
                </v-btn>
                <v-btn :color="$vuetify.theme.dark ? '#53ADD4' : '#2491BF'" icon v-if="hasEvents(date) && isNotPb(date) && (!!($store.getters.getTokenPayload?.permisos & $store.getters.getPermisos['CONTROL_HORAS']))" :to="`/horas/${ date }`" >
                  <v-icon small style="text-shadow: .6px .6px rgba(0, 0, 0, .7);">mdi-human-male</v-icon>
                </v-btn>
                <v-btn :color="$vuetify.theme.dark ? '#53ADD4' : '#2491BF'" icon v-if="hasEvents(date) && isNotPb(date) && calc(date) && (!!($store.getters.getTokenPayload?.permisos & $store.getters.getPermisos['RESUMEN_RENTABILIDAD']) || !!($store.getters.getTokenPayload?.permisos & $store.getters.getPermisos['EVENTOS']))" :to="`/costes/${ date }`" >
                  <v-icon small style="text-shadow: .6px .6px rgba(0, 0, 0, .7);">mdi-alarm-panel</v-icon>
                </v-btn>
                <v-btn icon outlined v-if="isCritico(date)" @click="criticas(date)">
                  <v-icon small>
                    mdi-alert
                  </v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  fab
                  small
                  :elevation="0"
                  :text="!present"
                  :color="present ? 'primary' : ''"
                  :class="{
                    'text-end': true,
                    'grey--text': past,
                    'mr-1': true,
                    'mb-1': true,
                  }"
                >
                  {{ day }}
                </v-btn>
              </div>
            </template>
          </v-calendar>

          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
            transition="slide-y-transition"
          >
            <DetallesEvento
              :selected-event="selectedEvent"
              @cambioEstado="(v) => (cambioEstado = v)"
              @cambioFeedback="(v) => (cambioFeedback = v)"
              @reload="getEventos"
            />
          </v-menu>

          <div class="d-flex mt-4">
            <v-btn icon outlined :color="$vuetify.theme.dark ? 'white' : 'black'" class="mx-1 justify-center" to="/eventos/festivos">
              <v-icon class="mx-auto" left>mdi-party-popper</v-icon>
            </v-btn>
            <v-btn icon outlined :color="$vuetify.theme.dark ? 'white' : 'black'" class="mx-1 justify-center" to="/jornadas">
              <v-icon class="mx-auto" left>mdi-timetable</v-icon>
            </v-btn>
            <v-btn icon outlined :color="$vuetify.theme.dark ? 'white' : 'black'" class="mx-1 justify-center" @click="search">
              <v-icon class="mx-auto" left>mdi-text-box-search-outline</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <div class="d-flex">
              <v-chip
                label
                outlined
                class="d-flex ml-2"
                v-for="tipo in leyendaTipos"
                :key="tipo.idTipo"
								@click.stop=" tipo.value = !tipo.value "
              >
                <v-avatar tile left size="12" class="pa-4" :color="tipo.color">
									<v-icon v-if="tipo.value" >mdi-eye</v-icon>
									<v-icon v-else >mdi-eye-off</v-icon>
								</v-avatar>
                <span>{{ tipo.tipo }}</span>
              </v-chip>
            </div>
          </div>
        </v-sheet>
      </v-col>
    </v-row>

    <ConfirmDialog ref="confirm" />

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      :value="cambioEstado"
      @input="(v) => (v === false ? (cambioEstado = null) : null)"
    >
      <v-toolbar dense>
        <v-toolbar-title>Cambiar estado {{ cambioEstado }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.stop="cambioEstado = null">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <cambio-estado
        @close="cambioEstado = null"
        @reload="getEventos"
        :key="cambioEstado"
        :idEvento="cambioEstado"
      ></cambio-estado>
    </v-dialog>


    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      :value="cambioFeedback"
      @input="(v) => (v === false ? (cambioFeedback = null) : null)"
    >
      <v-toolbar dense>
        <v-toolbar-title>Feedback {{ cambioFeedback }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.stop="cambioFeedback = null">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <cambio-feedback
        @close="cambioFeedback = null"
        @reload="getEventos"
        :key="cambioFeedback"
        :idEvento="cambioFeedback"
      ></cambio-feedback>
    </v-dialog>

    <imprimirRango ref="imprimir" :eventos="eventos" :rango="imprimirRange"></imprimirRango>
    <BuscarEvento ref="search" :eventos="eventos" @seleccionar="focus = $event; type = 'day'"></BuscarEvento>
  </v-card>
</template>

<script>
import { parseDate } from "@/utils/index.js";

export default {
  components: {
    CambioFeedback: () => import("../CambiarFeedback.vue"),
    CambioEstado: () => import("../CambiarEstado.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
    Evento: () => import("./Evento.vue"),
    DetallesEvento: () => import("./DetallesEvento.vue"),
    ImprimirRango: () => import("../ImprimirRango.vue"),
    BuscarEvento: () => import("../BuscarEvento.vue"),
  },
  props: {
    date: Date,
  },
  data() {
    return {
      auxDate: new Date(),
      check: 0,
      menu: false,
      menuPrint: false,
      imprimirRange: [],
      eventosImprimir: [],
      eventosCal: [],
      fechas: [],
      focus: Date.now(),
      ordenTipos: [6, 1, 2, 4, 3, 5],
      ordenHorarios: ["MAÑ", "MAÑ Y CO", "TD", "CO", "CO Y CE", "TAR", "TAR Y CE", "CE", "PTE"],
      ordenEspacios: ["PISCIS", "MASMONZON", "LALANNE", "LAUS", "CATERING"],
      ordenSalones: ["ROSA", "ROYAL", "CONTINENTAL", "LA CUCHARILLA", "MULTIUSOS", "PISTAS FLORIDA", "CLASH", "Blanco", "ANTIGUO", "LAGO", "LOS VIÑEDOS", "EXCLUSIVO", "null", null],
      eventos: [],
      fechas: [],
      festivos: [],
      jornadas: [],
      leyendaTipos: [],
      meses: [{ txt : "ENERO", val : 1},{ txt : "FEBRERO", val : 2},{ txt : "MARZO", val : 3},{ txt : "ABRIL", val : 4},{ txt : "MAYO", val : 5},{ txt : "JUNIO", val : 6},{ txt : "JULIO", val : 7},{ txt : "AGOSTO", val : 8},{ txt : "SEPTIEMBRE", val : 9},{ txt : "OCTUBRE", val : 10},{ txt : "NOVIEMBRE", val : 11},{ txt : "DICIEMBRE", val : 12}],
      mes: null,
      year: null,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      timer: null,
      typeToLabel: {
        month: "Mes",
        week: "Semana",
        day: "Día",
        "4day": "4 Días",
      },
      type: "month",
      cambioEstado: null,
      cambioFeedback: null,
      keyHelper: 0,
      weekdays: [1, 2, 3, 4, 5, 6, 0],
    };
  },
  mounted() {
    this.getEventos();
    // this.getEventosCal();
    this.getTiposEvento();
    this.getCriticos();
    this.getFestivos();
    this.getJornadas();
    this.getFechas();
  },
  methods: {
    search(){
      this.$refs.search.open()
    },
    auxFecha(fec){
      if (this.fechas.includes(fec)) return ['red']
    },
    imprimir(e){
      this.$refs.imprimir.open(e)
    },
    async getFechas(){
      this.loading = true;
      const dias = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/listado/diascriticos`,
      });
      dias.data.forEach(f => {
        this.fechas.push(f.fecha)
      });

      this.loading = false;
    },
    criticas(d){
      this.$router.push({ name: 'criticas', params: { d: d } })
    },
    async getCriticos(){
      this.loading = true;
      const dias = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/listado/diascriticos`,
      });
      dias.data.forEach(f => {
        this.fechas.push(f.fecha)
      });
      this.loading = false;
    },
    isCritico(d){
      return this.fechas.includes(d)
    },
    parseDate,
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
      // this.auxDate = new Date(this.$refs.calendar.title.toUpperCase().split(" ")[1], ["","ENERO", "FEBRERO", "MARZO", "ABRIL", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"].indexOf(this.$refs.calendar.title.toUpperCase().split(" ")[0]),1)
      // if (this.timer) clearTimeout(this.timer);
      // this.timer = setTimeout(() => {
      //   this.getEventosCal()
      // }, 500);
    },
    next() {
      this.$refs.calendar.next();
      // this.auxDate = new Date(this.$refs.calendar.title.toUpperCase().split(" ")[1], ["","ENERO", "FEBRERO", "MARZO", "ABRIL", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"].indexOf(this.$refs.calendar.title.toUpperCase().split(" ")[0]),1)
      // if (this.timer) clearTimeout(this.timer);
      // this.timer = setTimeout(() => {
      //   this.getEventosCal()
      // }, 500);
    },
    move(t) {
      this.$refs.calendar.move(t);
      // this.auxDate = new Date(this.$refs.calendar.title.toUpperCase().split(" ")[1], ["","ENERO", "FEBRERO", "MARZO", "ABRIL", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"].indexOf(this.$refs.calendar.title.toUpperCase().split(" ")[0]),1)
      // if (this.timer) clearTimeout(this.timer);
      // this.timer = setTimeout(() => {
      //   this.getEventosCal()
      // }, 500);
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    async getFestivos() {
      const { data } = await axios({
        url: "/festivos",
        // params: {
        //   start: start.date,
        //   end: end.date,
        // },
      });
      this.festivos = data;
    },
    async getJornadas() {
      const { data } = await axios({
        url: "/jornadas",
        // params: {
        //   start: start.date,
        //   end: end.date,
        // },
      });
      this.jornadas = data;
    },
    async getTiposEvento() {
      const { data } = await axios("/opciones/tipoEventos");
      this.leyendaTipos = data.map( x => ({...x, value: 1}) );
    },
    async getEventosCal(i){
      if (i){
        var viewStart = this.$refs.calendar.getStartOfWeek(i.start);
        var viewEnd = this.$refs.calendar.getEndOfWeek(i.end);
      }
      else{
        var viewStart = new Date(new Date().getFullYear(), new Date().getMonth(), 2).toISOString().split('T')[0];
        var viewEnd = new Date(new Date().getFullYear(),new Date().getMonth()+1,1).toISOString().split('T')[0];
      }
      const { data } = await axios({
        url: "/eventos/cal/",
        params:{
          start :viewStart,
          end: viewEnd
        }
      });
      
      this.eventosCal = data.map((evento) => {
        evento.datos = JSON.parse(evento.datos);
        evento.cliente = evento.datos.clientes[0];
        // evento.datos.tcos = (evento.datos.nNinos * 1 || 0) + (evento.datos.n * 1 || 0);
        evento.datos.tcos = (evento.datos.nAdultos * 1 || 0);
        const showAdultos =
          evento.idTipoEvento == 6 ? null : `(${evento.datos.tcos}${ (evento.datos.nNinos * 1) > 0 ? `+${evento.datos.nNinos * 1 || 0}` : ``})`;
          return {
          ...evento,
          color: (evento.colorTipoEvento || ''),
					date: evento.datos.fechaInicioEvento.split(' ')[0],
          start: parseDate(evento.datos.fechaInicioEvento, true),
          end: parseDate(evento.datos.fechaInicioEvento, true),
          name: [
            evento.datos.novios ||
              evento.datos.nino ||
              evento.datos.tipoCelebracion ||
              (evento.cliente?.nombreCompleto || 0).split(" ")[0] ||
              "",
            showAdultos,
            evento.siglasEspacio || evento.nombreEspacio || "",
            (evento.siglasSalon == 'null' ? null : evento.siglasSalon) || (evento.nombreSalon == 'null' ? null : evento.nombreSalon) || "",
            evento.siglasHorario || evento.nombreHorario || "",
            evento.idTipoEvento == 5 ? evento.datos.ubBodaReal : null
          ]
            .filter((x) => x)
            .join(" - "),
        };
      })
      this.eventosCal.sort(
        (a, b) => {
          var keyA = this.ordenTipos.indexOf(a.idTipoEvento);
          var keyB = this.ordenTipos.indexOf(b.idTipoEvento);
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;

          keyA = this.ordenHorarios.indexOf(a.siglasHorario);
          keyB = this.ordenHorarios.indexOf(b.siglasHorario);
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;

          keyA = this.ordenEspacios.indexOf(a.nombreEspacio);
          keyB = this.ordenEspacios.indexOf(b.nombreEspacio);
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;

          keyA = this.ordenSalones.indexOf(a.nombreSalon);
          keyB = this.ordenSalones.indexOf(b.nombreSalon);

          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;

          return 0;
        });
    },  
    async getEventos() {
      const { data } = await axios({
        url: "/eventos/",
      });

      this.eventos = data.map((evento) => {
        evento.datos = JSON.parse(evento.datos);
        evento.cliente = evento.datos.clientes[0];
        // evento.datos.tcos = (evento.datos.nNinos * 1 || 0) + (evento.datos.n * 1 || 0);
        evento.datos.tcos = (evento.datos.nAdultos * 1 || 0);
        const showAdultos =
          evento.idTipoEvento == 6 ? null : `(${evento.datos.tcos}${ (evento.datos.nNinos * 1) > 0 ? `+${evento.datos.nNinos * 1 || 0}` : ``})`;
          return {
          ...evento,
          color: evento.colorTipoEvento,
					date: (evento.datos?.fechaInicioEvento || '2024-12-31').split(' ')[0],
          start: parseDate(evento.datos.fechaInicioEvento, true),
          end: parseDate(evento.datos.fechaInicioEvento, true),
          name: [
            evento.datos.novios ||
              evento.datos.nino ||
              evento.datos.tipoCelebracion ||
              (evento.cliente?.nombreCompleto || '').split(" ")[0] ||
              "",
            showAdultos,
            evento.siglasEspacio || evento.nombreEspacio || "",
            (evento.siglasSalon == 'null' ? null : evento.siglasSalon) || (evento.nombreSalon == 'null' ? null : evento.nombreSalon) || "",
            evento.siglasHorario || evento.nombreHorario || "",
            evento.idTipoEvento == 5 ? evento.datos.ubBodaReal : null
          ]
            .filter((x) => x)
            .join(" - "),
        };
      });

      this.eventos.sort(
        (a, b) => {
          var keyA = this.ordenTipos.indexOf(a.idTipoEvento);
          var keyB = this.ordenTipos.indexOf(b.idTipoEvento);
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;

          keyA = this.ordenHorarios.indexOf(a.siglasHorario);
          keyB = this.ordenHorarios.indexOf(b.siglasHorario);
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;

          keyA = this.ordenEspacios.indexOf(a.nombreEspacio);
          keyB = this.ordenEspacios.indexOf(b.nombreEspacio);
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;

          keyA = this.ordenSalones.indexOf(a.nombreSalon);
          keyB = this.ordenSalones.indexOf(b.nombreSalon);

          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;

          return 0;
        });
      this.selectedOpen = false;
      this.keyHelper++;
    },
    hasEvents(date) {
      return this.eventos.filter((ev) => ev.date == date).length > 0;
    },
    isNotPb(date) {
      return this.eventos.filter((ev) => ev.date == date && [1,2,3,4].includes(ev.idTipoEvento)).length > 0;
    },
    isCat(date) {
      return this.eventos.filter((ev) => ev.date == date && ("CATERING".includes(ev.nombreEspacio) || "PONENT".includes(ev.nombreEspacio)) ).length > 0;
    },
    calc(date) {
      return this.eventos.filter((ev) => ev.date == date && (ev.idTipoEvento == 1 || (((ev.datos.nAdultos || 0) + (ev.datos.nNinos || 0)) > 49))).length > 0;
    }
  },
	computed : {
		eventosFiltrados() {
			const tiposPermitidos = this.leyendaTipos.filter( x => x.value ).map( x => x.idTipoEvento )
			return this.eventos.filter( ev => tiposPermitidos.includes( ev.idTipoEvento ) )
		}
	},
  watch:{
    check: function (n, o) {
      if (n % 2 == 0) this.imprimir()
    }
  }
};
</script>

<style>
.v-calendar-weekly__day {
  min-height: 80px;
  padding: 3px !important;
}
.v-calendar-weekly__day.v-present {
  border: 1px solid var(--v-primary-base) !important;
}
.v-event {
  height: unset !important;
}
.v-event .v-event--thick {
  height: 25px !important;
}
</style>